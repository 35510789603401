var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tui tuim ui middle aligned center aligned grid" },
    [
      _c(
        "div",
        { staticClass: "tui tuim column" },
        [
          _c("t-auth-header"),
          _c("div", { staticClass: "tui tuim ui card" }, [
            _c("div", { staticClass: "content left aligned spacer" }, [
              _c(
                "form",
                {
                  class: "tui tuim ui form " + (_vm.error ? "error" : ""),
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.authenticate()
                    }
                  }
                },
                [
                  _vm.error
                    ? _c(
                        "div",
                        {
                          class:
                            "alert " +
                            (_vm.error ? "error" : "") +
                            " left aligned"
                        },
                        [
                          _c("t-icon", {
                            attrs: { icon: ["fas", "exclamation-circle"] }
                          }),
                          _c("p", [_vm._v("E-mail ou senha incorretos.")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      class:
                        "field " +
                        (_vm.error || (this.$v.$invalid && this.$v.$error)
                          ? "error"
                          : "")
                    },
                    [
                      _c("label", { attrs: { for: "authEmail" } }, [
                        _vm._v("E-mail")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.auth.email,
                            expression: "form.auth.email"
                          }
                        ],
                        attrs: {
                          type: "text",
                          id: "authEmail",
                          name: "email",
                          placeholder: "exemplo@e-mail.com.br"
                        },
                        domProps: { value: _vm.form.auth.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form.auth,
                              "email",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm.submitted && !_vm.$v.form.auth.email.required
                        ? _c(
                            "label",
                            {
                              staticClass: "validate error",
                              attrs: { for: "authEmail" }
                            },
                            [
                              _vm._v("O "),
                              _c("strong", [_vm._v("E-mail")]),
                              _vm._v(" é obrigatório.")
                            ]
                          )
                        : _vm._e(),
                      _vm.submitted && !_vm.$v.form.auth.email.email
                        ? _c(
                            "label",
                            {
                              staticClass: "validate error",
                              attrs: { for: "authEmail" }
                            },
                            [
                              _vm._v("O "),
                              _c("strong", [_vm._v("E-mail")]),
                              _vm._v(" é inválido.")
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class:
                        "field " +
                        (_vm.error || (this.$v.$invalid && this.$v.$error)
                          ? "error"
                          : "")
                    },
                    [
                      _c("label", { attrs: { for: "authPassword" } }, [
                        _vm._v("Senha")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.auth.password,
                            expression: "form.auth.password"
                          }
                        ],
                        attrs: {
                          type: "password",
                          id: "authPassword",
                          name: "password",
                          placeholder: "•••••••••••"
                        },
                        domProps: { value: _vm.form.auth.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form.auth,
                              "password",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm.submitted && !_vm.$v.form.auth.password.required
                        ? _c(
                            "label",
                            {
                              staticClass: "validate error",
                              attrs: { for: "authPassword" }
                            },
                            [
                              _vm._v("O "),
                              _c("strong", [_vm._v("Senha")]),
                              _vm._v(" é obrigatório.")
                            ]
                          )
                        : _vm._e(),
                      _vm.submitted && !_vm.$v.form.auth.password.minLength
                        ? _c(
                            "label",
                            {
                              staticClass: "validate error",
                              attrs: { for: "authPassword" }
                            },
                            [
                              _vm._v("O "),
                              _c("strong", [_vm._v("Senha")]),
                              _vm._v(
                                " deve conter no mínimo " +
                                  _vm._s(
                                    _vm.$v.form.auth.password.$params.minLength
                                      .min
                                  ) +
                                  " letras."
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _c("button", {
                    class:
                      "tui tuim ui primary large fluid " +
                      (_vm.isLoading ? "loading" : "") +
                      " " +
                      (_vm.isLoading ? "disabled" : "") +
                      " button",
                    attrs: { type: "submit", disabled: _vm.isLoading },
                    domProps: { innerHTML: _vm._s(_vm.$t("Enter")) }
                  })
                ]
              )
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }