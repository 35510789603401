import { mapState, mapActions, mapGetters } from 'vuex'
import { required, minLength, email } from 'vuelidate/lib/validators'
import { isProduction } from '@/config'
import { getMonth, getYear } from '@/helpers'

export default {
  name: 'TAuthLogin',
  title: ({ title }) => title,
  components: {
    TAuthHeader: () => import('@/components/auth/header.vue')
  },
  data () {
    return {
      title: 'Login',
      context: 'login context',
      form: {
        auth: {
          email: '',
          password: ''
        }
      },
      submitted: false,
      invalid: false,
      error: false,
      redirectTo: ""
    }
  },
  validations: {
    form: {
      auth: {
        email: { required, email },
        password: { required, minLength: minLength(6) }
      }
    }
  },
  computed: {
    ...mapState({
      isLoading: ({ auth }) => auth.status === 'loading'
    }),
    ...mapGetters({
      isDirectMail: 'isDirectMail',
      hasDebitAccount: 'hasDebitAccount'
    })
  },
  methods: {
    ...mapActions(['authenticateUser']),
    authenticate () {
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.authenticateUser(this.form.auth)
        .then(() => {
            if (this.redirectTo) {
              this.redirectTo = this.redirectTo.split("/")
              const reportId = this.redirectTo[3]
              this.$router.push({
                name: 'transactions.downloadFile',
                params: { reportId: reportId }
              })
              this.redirectTo = ""
            } else if (this.isDirectMail && !this.hasDebitAccount) {
              this.$router.push({ name: 'transactions' })
            } else {
              this.$router.push({ name: 'files', params: { year: getYear(), month: getMonth(), type: 'debit' } })
            }
          })
          .catch(err => {
            this.error = true
            this.$log.error('error', err)
          })
      }
    }
  },
  mounted () {
    if (!isProduction) {
      this.form.auth = {
        email: 'developers@trackmob.com.br',
        password: '123123123'
      }
    }

    const getURL = window.location.host
    const tenant = getURL.split('.')[0]
    localStorage.setItem('tenant', tenant)
    if (localStorage.getItem("url_transaction_file_download")) {
      this.redirectTo = localStorage.getItem("url_transaction_file_download")
    }
  }
}
